<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_info_contents">
				<div class="mem_contents_header">
					<div class="title-txt">내프로젝트 &gt; 프로젝트 인터뷰<br/>
						<p><span class="prj_ct">{{interviewReqCount}}</span>개의 인터뷰 요청이 있습니다.</p>
					</div>
				</div>
				<div class="filter">
					<div class="filter_box">
						<ul>
							<li><p class="text">NO.</p></li>
							<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">최신 요청 순</p><span>ㅣ</span></li>
							<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">최신등록 프로젝트 순</p></li>
						</ul>
					</div>
				</div>

				<div v-if="pageInfo.totalRecordCount <= 0" class="itv-box">
					<div class="no"></div>
					<div class="view none">
						관련 내용이 존재하지 않습니다!
					</div>
				</div>
				<div v-else v-for="(interview, index) in interviewList" :key="interview.interviewReqSeq" class="itv-box flex-box">
					<div class="no">
						{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}
					</div>
					<div class="view box-hover">
						<div class="req">
							<img class="itv" src="/images/tec/prj/interview.png" alt="인터뷰"/>
							<div class="itv-outline">
								<div class="tit">프로젝트 인터뷰 요청
									<span v-if="(interview.interviewStatusCd == '01' || interview.interviewStatusCd == '02') && interview.newYn == 'Y'">new</span>
									<span v-if="interview.interviewStatusCd != '01' && interview.interviewStatusCd != '02'" class="cp">인터뷰 완료</span>
								</div>

								<div class="prj_contents">
									<div class="regi">
										<div class="tit int_prj sco_mark" v-if="interview.projectDivCd == '3'">공급기업 지정</div>
										<div class="tit int_prj" v-else>프로젝트명</div>
										<div class="name prj-tit-hover" style="width: 444px;" @click="clickProjectInfo(interview.projectSeq)">{{interview.projectNm}}</div>         
										<div class="int_box">
											<div class="list-view int_prj">
												<div class="label ivl03">개요</div>
												<div class="list text">{{interview.projectDtlDesc}}</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl04">고객사</div>
												<div class="list" style="width: 444px;">
													<div class="add">{{interview.clientNm}}</div>
													<div class="loca"><img src="/images/location.png" alt="위치" />{{interview.projectLocCont}}</div>
												</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl05">산업분야</div>
												<div class="list text" style="width: 446px;"><SelectComp type="text" cdId="PRO105" :value="interview.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="interview.indtypeClassCd"/></div>
											</div>
										</div>
									</div>
									
									<div class="int_box">                                            
										<div class="list-view int_prj">
											<div class="label ivl04">수행사</div>
											<div class="list">{{interview.corpNm}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기간</div>
											<div class="list">{{interview.projectStartEndYyyymmdd}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기술</div>
											<div class="list text" style="width: 444px;">{{interview.devTechCont}}</div>
										</div>                                           
                                	</div> 
								</div>


								

								<!-- <div class="list-view">
									<div class="label">프로젝트명</div><div class="list text">{{interview.projectNm}}</div>
								</div>
								<div class="list-view">
									<div class="label ivl04">수행사</div><div class="list">{{interview.corpNm}}<div class="loca"><img class="loca" src="/images/location.png" alt="위치"/>{{interview.projectLocCont}}</div></div>
								</div>
								<div class="list-view">
									<div class="label ivl04">담당자</div><div class="list">{{interview.corpMberNm}}</div>
								</div> 
								<div class="list-view">
									<div class="label ivl05">산업분야</div><div class="list"><SelectComp type="text" cdId="PRO105" :value="interview.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="interview.indtypeClassCd"/></div>
								</div>
								<div class="list-view">
									<div class="label ivl05">개발기간</div><div class="list">{{interview.projectStartEndYyyymmdd}}</div>
								</div>
								<div class="list-view">
									<div class="label ivl03">개요</div><div class="list text">{{interview.projectDtlDesc}}</div>
								</div> -->

							</div>
						</div>

						<!-- 면접요청, 면접중 -->
						<div v-if="interview.interviewStatusCd == '01' || interview.interviewStatusCd == '02'" class="noti">
							<img src="/images/tec/prj/interview-date.png" alt="요청일" />
							<span class="tit mt-4">인터뷰 요청</span>                    
							<div class="date mt-7">{{interview.interviewYyyymmdd}} / {{interview.interviewDay}} {{interview.interviewHhmm}}</div>
							<span class="notice mt-3">
								인터뷰 세부사항은 개별적으로 연락드립니다!
							</span>
                   		</div>

						<!-- 합격 -->
						<div v-if="interview.interviewStatusCd == '04'" class="noti">
							<span class="tit">프로젝트 인터뷰 결과</span>
							<img class="pass" src="/images/tec/prj/pass.png" alt="합격이미지" />
							<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
							<span class="notice">프로젝트 참여관련 세부사항은 개별적으로 연락드립니다!</span>
                    	</div>

						<!-- 불합격 -->	
						<div v-if="interview.interviewStatusCd == '03' || interview.interviewStatusCd == '99'" class="noti">
							<span class="tit">프로젝트 인터뷰 결과</span>
							<span class="guide">죄송합니다!<br/>불합격 하셨습니다.</span>
							<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
							<span class="notice">해당 결과는 귀하의 역량과 상관 없음을 알려드립니다.</span>
                    	</div>

						<!-- 면접요청, 면접중 -->
						<!-- <div v-if="interview.interviewStatusCd == '01' || interview.interviewStatusCd == '02'" class="noti">
							<img src="/images/tec/prj/date.png" alt="요청일"/>인터뷰 요청일
							<div class="date">{{interview.interviewYyyymmdd}} / {{interview.interviewDay}} {{interview.interviewHhmm}}</div>
							<div class="zoom">
								카카오톡으로 인터뷰 참가 링크를 보내드립니다!
								<img src="/images/tec/prj/zoom.png" alt="줌이미지"/><a href="https://zoom.us/" target="_blank"><span>줌 시작하기 &#9654;</span></a>
							</div>
						</div> -->

						<!-- 합격 -->
						<!-- <div v-if="interview.interviewStatusCd == '04'" class="noti">
							<span class="tit">프로젝트 인터뷰 결과</span>
							<img class="pass" src="/images/tec/prj/pass.png" alt="합격이미지"/>
							<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
							<span class="notice">프로젝트 참여관련 세부사항은 개별적으로 연락드립니다!</span>
						</div> -->

						<!-- 불합격 -->
						<!-- <div v-if="interview.interviewStatusCd == '03' || interview.interviewStatusCd == '99'" class="noti">
							<span class="tit">프로젝트 인터뷰 결과</span>
							<span class="guide">죄송합니다!<br/>불합격 하셨습니다.</span>
							<div class="field">프로젝트 지원분야 [{{interview.workCont}}]</div>
							<span class="notice">프로젝트 참여관련 세부사항은 개별적으로 연락드립니다!</span>
						</div> -->

					</div>
				</div>

				<!--페이징 컴포넌트 -->
				<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
				<!--// 페이징 컴포넌트 -->

			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';

export default {

	components : {
		PagingComp
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				orderGubun : '1',	// 1:최신요청순, 2:최신등록 프로젝트 순
			}, // 검색조건		

			interviewList : [],
			interviewReqCount : 0,
			pageInfo : {},
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
	},
	mounted() {
		// console.log('mounted');
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList() {
			this.$.httpPost('/api/prj/tec/getInterviewList', this.input)
				.then(res => {
					// console.log(res.data);
					this.interviewList 		= res.data.list;
					this.interviewReqCount 	= res.data.interviewReqCount;
					this.pageInfo 			= res.data.pageInfo;
					
					window.scroll(0, 0);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList();
		},

		clickProjectInfo(projectSeq) {
			var param = {
				projectSeq : projectSeq
			};
			
			this.$.popup('/tec/prj/PRJ102P01', param, {width:0, height:0})
				// .then(res => {})
				// .catch(err => {})
				;
		},

	},
};
</script>
